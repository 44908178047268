import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HomeHeaderComponent } from "./home-header/home-header.component";
import { MaterialModule } from "../material.module";
import { AvatarModule } from "ngx-avatar";
import { TranslateModule } from "@ngx-translate/core";
import { AttachmentPoupuComponent } from "./attachment-poupu/attachment-poupu.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MultipleErrorMessageComponent } from "./multiple-error-message/multiple-error-message.component";
import { ErrorMessageComponent } from "./error-message/error-message.component";
import { TextAreaComponent } from "./text-area/text-area.component";
import { IdentificationDirective } from "./directives/identification.directive";
import { AddSymbolDirective } from "./directives/add-symbol.directive";

@NgModule({
  declarations: [
    HomeHeaderComponent,
    AttachmentPoupuComponent,
    MultipleErrorMessageComponent,
    ErrorMessageComponent,
    TextAreaComponent,
    IdentificationDirective,
    AddSymbolDirective,
    TextAreaComponent,
  ],
  imports: [
    CommonModule,
    AvatarModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    HomeHeaderComponent,
    TextAreaComponent,
    IdentificationDirective,
    AddSymbolDirective,
  ],
})
export class SharedModule {}
