import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home.component";
import { OrgInsuranceComponent } from "./org-insurance/org-insurance.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { AuthorizationGuard } from "../core/authorization.guard";
import { TermConditionComponent } from "./term-condition/term-condition.component";
import { DigitalAssetsComponent } from "./digital-assets/digital-assets.component";
import { BreadcrumbModule } from "xng-breadcrumb";

const dashboardRoutes: Routes = [
  {
    path: "digitalAssets",
    loadChildren: () =>
      import("./digital-assets/digital-assets.module").then(
        (d) => d.OrgInsuranceModule
      ),
    data: {
      allowedRoles: [4],
      breadcrumb: { skip: true },
    },
    canActivate: [AuthorizationGuard],
  },
  {
    path: "",
    data: { title: "home" },
    component: HomeComponent,
    canActivateChild: [AuthorizationGuard],
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((d) => d.DashboardModule),
        data: {
          allowedRoles: [1, 2],
          breadcrumb: { skip: true },
        },
      },
      {
        path: "org",
        loadChildren: () =>
          import("./org-insurance/org-insurance.module").then(
            (o) => o.OrgInsuranceModule
          ),
        data: {
          allowedRoles: [1, 2],
          breadcrumb: { label: "Organizations" },
        },
      },
      {
        path: "doctor",
        loadChildren: () =>
          import("./doctor/doctor.module").then((d) => d.DoctorModule),
        data: {
          allowedRoles: [1, 2],
          breadcrumb: { skip: true },
        },
      },
      {
        path: "agents",
        loadChildren: () =>
          import("./agent/agent.module").then((d) => d.AgentModule),
        data: {
          allowedRoles: [1, 2],
        },
      },
      {
        path: "admin",
        loadChildren: () =>
          import("./admin/admin.module").then((a) => a.AdminModule),
        data: {
          allowedRoles: [1],
          breadcrumb: { skip: true },
        },
      },
      {
        path: "patients",
        loadChildren: () =>
          import("./patient/patient.module").then((p) => p.PatientModule),
        data: {
          allowedRoles: [1, 2],
          breadcrumb: { skip: true },
        },
      },
      {
        path: "allConsults",
        loadChildren: () =>
          import("./allconsult/allconsult.module").then(
            (p) => p.AllconsultModule
          ),
        data: {
          allowedRoles: [1, 2, 3],
          breadcrumb: { skip: true },
        },
      },
      {
        path: "allies",
        loadChildren: () =>
          import("./allies/allies.module").then((o) => o.AlliesModule),
        data: {
          allowedRoles: [1, 2, 3],
          breadcrumb: { label: "Allies" },
        },
      },
      {
        path: "logs",
        loadChildren: () =>
          import("./logs/logs.module").then((p) => p.LogsModule),
        data: {
          allowedRoles: [3],
          breadcrumb: { skip: true },
        },
      },
      {
        path: "change-password",
        component: ChangePasswordComponent,
        data: { title: "Change Password", breadcrumb: { skip: true } },
      },
      {
        path: "agreements",
        component: TermConditionComponent,
        data: { 
          allowedRoles: [1,2,3] ,
          title: "Agreements", 
          breadcrumb: { skip: true } 
        },
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(dashboardRoutes), BreadcrumbModule],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
